<template>
  <div role="main" class="main">
    <header class="header">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12">
            <nav class="navbar navbar-expand-md navbar-dark pl-lg-0">
              <a class="navbar-brand" href="javascript:void(0);">{{
                event_info ? event_info.wed_name : "WED WITH US"
              }}</a>
            </nav>
          </div>
        </div>
      </div>
    </header>
     <section>
      <div class="registration-page-content common-page">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="row">
                    <p v-if="event_info">PARTING</p>
                  </div>
                  <div class="custom-group mb-2">
                    <textarea class="form-control" rows="3" placeholder="Please leave a personal message or a memory." v-model="message"></textarea>
                    <!-- <VueEmoji
                      ref="emoji"
                      @input="onInput"
                      :value="myText"
                      height="100"
                      width="367px !important"
                    /> -->
                  </div>
                </div>
                <div class="col-12 col-md-4"></div>
                <div class="col-12 col-md-8 text-right">
                  <b-button
                    type="button"
                    class="btn-primary white-border"
                    @click="goNext('condolence')"
                    >Leave advice for the couple</b-button
                  >
                </div>
                <div class="col-12 col-md-4"></div>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="row">
                <div class="col-12 col-md-2"></div>
                <div class="col-12 col-md-7" v-if="event_info">
                  <div class="c-scroll">
                    <div  v-for="(input,k) in imagesArray" :key="k" class="mb-3">
                    <img
                      :src="input.program_url"
                      class="img-fluid c-img-scoll"
                    />
                    </div>
                  </div>
                  <!-- <img
                     :src="event_info.program_url"
                    class="img-fluid pt-3"
                  /> -->
                </div>
                <div class="col-12 col-md-3"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="registration-page-footer">
        <div class="footer-wrapper container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.tel!=''">
              <div class="img-avatar-banner" @click="goNext('voicemail')">
                <img
                  src="../../assets/img/voicemail.jpg"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Record a congratulations message</div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.gift_pay_url!=''">
              <div class="img-avatar-banner" @click="goNext('donation')">
                <img
                  src="../../assets/img/DonateWithPaypal.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Gift with <span>PayPal</span></div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center">
              <div class="img-avatar-banner">
                <img
                  src="../../assets/img/upload-photo.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name" @click="goNext('upload_photo')">
                  Upload a photo
                </div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.reminder_url!=''">
              <div class="img-avatar-banner" @click="goNext('calendar')">
                <img
                  src="../../assets/img/calendar.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Calendar reminder</div>
              </div>
            </div>
            <div class="col-lg-2 c-col-lg-2 d-flex justify-content-center" v-if="event_info && event_info.gift_registry_url!=''">
              <div class="img-avatar-banner" @click="goNext('shop')">
                <img
                  src="../../assets/img/gift.png"
                  class="img-fluid banner-avatar round-image"
                />
                <div class="banner-name">Gift Registry</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import { apiBaseUrl, liveBaseUrl } from "../../constants/config";
import { mapActions } from "vuex";
import VueEmoji from "emoji-vue";

export default {
  data() {
    return {
      event_info: null,
      message: "",
      myText: "",
      imagesArray:[]
    };
  },
  mounted() {
    this.event_id= this.$route.params.p_id;
    console.log("[[[[[[",this.event_id)
     getApiManager()
        .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
          event_id: this.event_id,
        })
      .then((response) => {
        let res = response.data;
        console.log("[[[[",res)
        if (res.status === true) {
          let detail = res.data[0];
          this.event_info = detail;
          getApiManager()
          .post(`${apiBaseUrl}/api/admin/event/getuploadImagebyEventId`, {
            event_id:  this.event_id,
          })
          .then((response) => {
              this.imagesArray=response.data.detail;
              if(this.imagesArray && this.imagesArray.length>0){
              for(var i=0;i<this.imagesArray.length;i++){
                this.imagesArray[i].program_url=`${apiBaseUrl}` + "/" + this.imagesArray[i].program_url
              }
            }
          })
          // this.event_info.program_url =
          //   `${apiBaseUrl}/` + this.event_info.program_url;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.connectSocket();
  },
  methods: {
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function(event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        if (data.type == "start_service") {
          console.log("{{{{{{startservice");
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/speaker/whereby?x=" + randomnumber);
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    onInput(event) {
      this.message = event.data;
    },
    clearTextarea() {
      this.$refs.emoji.clear();
    },
    ...mapActions(["signOut"]),
    // logout() {
    //   if (self.OTSession) {
    //     self.OTSession.disconnect();
    //   }
    //   getApiManager()
    //     .post(`${apiBaseUrl}/api/speaker/profile/modifylive`, {
    //       live: 0,
    //       content_type_id: this.content_type_id,
    //     })
    //     .then((response) => {
    //       localStorage.setItem("Live_id", null);
    //       this.signOut();
    //       this.$router.push("/login");
    //       window.location.reload();
    //     });
    // },
    goNext(page) {
      if (page == "condolence") {
        if (this.message == "") {
          this.$notify({
            group: "foo",
            type: "warn",
            title: "Waring",
            text: "Please input the message.",
            animation_type: "slide",
          });
          return;
        }
        getApiManager()
          .post(`${apiBaseUrl}/api/speaker/condolence/create`, {
            message: this.message,
          })
          .then((response) => {
            let res = response.data;
            if (res.status === true) {
              let randomnumber = Math.floor(Math.random() * 10000) + 1;
              this.$router.push("/speaker/condolence/" + res.condolence_id+"/"+this.event_id+"?x=" + randomnumber);
            } else {
              this.$notify({
                group: "foo",
                type: "warn",
                title: "Error",
                text: "Server Error",
                animation_type: "slide",
              });
              return;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (page == "upload_photo") {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/" + page+"?x=" + randomnumber);
      }
      else if (page == "shop") {
        window.open(this.event_info.gift_registry_url, "_blank");
      } else if (page == "donation") {
        window.open(this.event_info.gift_pay_url, "_blank");
      } else if (page == "calendar") {
        window.open(this.event_info.reminder_url, "_blank");
      } else if (page == "zoom") {
        window.open(this.event_info.zoom_meeting_url, "_blank");
      } else {
        let randomnumber = Math.floor(Math.random() * 10000) + 1;
        this.$router.push("/speaker/page/" + page+"?x=" + randomnumber);
      }
    },
  },
  components: {
  },
  computed: {},
  watch: {},
};
</script>
<style>
.navbar{
  padding: 0;
}
@font-face {
  font-family: "Fontin-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Regular.woff") format("woff");
}
@font-face {
  font-family: "Fontin-Bold";
  font-style: normal;
  font-weight: normal;
  src: url("../../assets/fonts/Fontin-Bold.woff") format("woff");
}
.registration-page-content {
  height: calc(100vh - 135px);
  padding-top: 9rem;
  padding-bottom: 4rem;
  background-image: url("../../assets/img/back-img.png") !important;
  background-size: contain;
}
.registration-page-footer {
  position: relative;
  background-image: url("../../assets/img/footer-img.png");
  background-repeat: repeat-y;
  background-position: left top;
  background-size: contain;
  padding: 15px 0;
  border-image-slice: 1;
  border-top: 5px solid;
  border-image-source: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(119, 40, 3, 1) 0%,
    rgba(163, 62, 11, 1) 15%,
    rgba(195, 85, 12, 1) 33%,
    rgba(102, 29, 3, 1) 59%,
    rgba(102, 21, 3, 1) 64%,
    rgba(102, 29, 3, 1) 76%,
    rgba(157, 57, 6, 1) 88%
  );
}
.registration-page-footer::before {
  content: "";
  height: 100%;
  width: 100%;
  background: #000000b0;
  position: absolute;
  top: 0;
  left: 0;
}
.page-heading h3 {
  color: #fff;
  font-family: "Fontin-Regular";
}
.arrow-icon-wrapper {
  height: 24px;
  width: 24px;
}
.avatar-img img {
  height: 150px;
  width: 150px;
  border: 3px solid #fff;
}
.white-border {
  border: 1px solid #fff !important;
  color: #fff !important;
  background: #000000a0 !important;
}
::placeholder {
  color: #fff !important;
}
.img-avatar-banner {
  display: flex;
  align-items: center;
}
.banner-avatar {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border: 2px solid #fff;
  position: relative;
  z-index: 2;
}
.banner-name {
  color: #fff;
  font-weight: 400;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.7);
  padding: 8px 30px;
  position: relative;
  margin-left: -23px;
  z-index: 1;
  cursor: pointer;
}
.banner-name span {
  font-weight: 900;
}
.footer-wrapper {
  /* padding: 0 12vw; */
}
.c-column-flex {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.text-normal {
  font-size: 14px !important;
  font-weight: 700;
  line-height: 1.3;
}
.select-profile-img {
  height: 125px;
  width: 115px;
  min-height: 125px;
  min-width: 115px;
  object-fit: cover;
  border: 3px solid #fff;
  border-bottom: 10px solid #fff;
}
.c-img-scoll {
  height: 370px;
    object-fit: cover;
}
.c-scroll{
  height: calc(100vh - 400px);
  overflow: auto;
}
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #772803;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #772803;
}
</style>
